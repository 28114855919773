import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"

const SetupPhoneEventDialog = ({ open, closeDialog, isLoading, formLoading, availableNumbers, selectedNumber, setSelectedNumber, handleSubmit }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-md sm:p-6">
                <div className="flex justify-between">
                  <Dialog.Title as="h3" className="text-xl font-medium">
                    Select an available number:
                  </Dialog.Title>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={closeDialog}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {isLoading ? <p className="mt-6">Loading...</p> : (
                  <>
                    <div className="my-6 space-y-3">
                      {availableNumbers.length > 0 ? availableNumbers.map((numbr) => (
                        <div key={numbr} className="flex items-center">
                          <input
                            id={numbr}
                            name="assign-session"
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            value={numbr}
                            checked={selectedNumber === numbr}
                            onChange={() => setSelectedNumber(numbr)}
                          />
                          <label htmlFor={numbr} className="ml-3 block text-sm/6 font-medium text-gray-900">
                            {numbr}
                          </label>
                        </div>
                      )) : <p>No available number found.</p>}
                    </div>
                    <div className="flex flex-shrink-0 justify-end">
                      <button
                        type="submit"
                        className="justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:opacity-25 disabled:cursor-not-allowed"
                        onClick={handleSubmit}
                        disabled={formLoading}
                      >
                        {formLoading ? 'Registerning...' : 'Select this number'}
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
};

export default SetupPhoneEventDialog;
